.dashboard-container {
  display: flex;
  flex-direction: column;
}

.dashboard {
  color: #333;
}

#hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #b2b3b7;
  padding: 0 10% 3% 10%;
}

/*HERO*/

.d-hero-text {
  width: 40%;
}

.d-hero-text h1 {
  font-size: 3rem;
  font-weight: 900;
}

.d-hero-text p {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.d-hero-text-last {
  font-style: italic;
}

.d-hero-image {
  width: 800px;
}

/*PROPERTY*/

#properties {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 10% 3% 10%;
}

#properties h2 {
  margin-top: 3rem;
  margin-bottom: 1rem;
  align-self: flex-start;
  color: #333;
}

.property-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* margin: 0 10% 3% 10%; */
}

.p-currencies {
  display: flex;
  align-items: center;
}

.p-currency-grp {
  margin-left: 0.5rem;
}

.p-currency-grp button {
  border: 1px solid #c13236;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.btn-pound,
.btn-dollar,
.btn-euro {
  background-color: white;
}
.btn-naira {
  background-color: #c13236;
  color: white;
}

.properties-grid {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 2rem;
  flex-wrap: wrap;
  column-gap: 1.2%;
}

.properties-grid a {
  text-decoration: none;
}

.property-card {
  position: relative;
  width: 24%;
  height: 26rem;
  border: 1px solid #b2b3b7;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 20px 0px #b2b3b7;
  /* padding-bottom: 1rem; */
  margin-bottom: 3rem;
}

.property-image {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  width: 100%;
  height: 250px;
}

.p-card-notes {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem 1.2rem;
  color: #333;
}

.p-card-icon {
  margin-right: 0.2rem;
  color: #c13236;
}

.p-name,
.p-type {
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0.5rem;
}

.p-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.p-price {
  margin-right: 0.2rem;
}

.p-location {
  display: flex;
  align-items: center;
  text-align: center;
  /* margin-bottom: 1rem; */
}

.p-mortgage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  background-color: #c13332;
  font-size: 12px;
  text-align: center;
  color: white;
  font-weight: 700;
}

.p-mortgage p {
  margin: 0;
  padding: 0;
}

.p-price {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: 700;
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border: none;
  background-color: #c13236;
  color: white;
}

.p-arrows {
  display: flex;
  justify-content: space-between;
  width: 5%;
}

/* ABOUT US */

#about-us {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10% 3% 10%;
}

.about-img {
  width: 38%;
}

.about-img img {
  border-radius: 2rem;
  width: 100%;
  height: 400px;
}

.about-notes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
}

#about-us p {
  margin-top: 1rem;
}

#about-us h3 {
  margin-top: 0.5rem;
}

.services {
  padding: 0 10% 1% 10%;
  display: flex;
  justify-content: space-between;
}

a {
  text-decoration: none;
}

.service-card {
  border: 1px solid #b2b3b7;
  width: 33%;
  padding: 2rem 3rem;
  text-align: center;
  border-radius: 1rem;
}
.service-card img {
  border: 1px solid #b2b3b7;
  padding: 0.5rem;
  width: 80px;
  height: 75px;
  border-radius: 50%;
  filter: grayscale(90%);
  box-shadow: 2px 1px 4px 1px #b2b3b7;
}

.service-card h4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.about-cta-btn {
  width: 10%;
  margin-left: 10%;
  margin-top: 0;
  padding: 0.7rem 1rem;
  cursor: pointer;
  font-weight: 700;
  color: white;
  border: 1px solid #c13236;
  background-color: #c13332;
  border-radius: 1rem;
}

.agent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5% 10% 0 10%;
  padding: 1rem 2rem;
  background-color: #c13332;
}

.agent a {
  text-decoration: none;
}

.icon-users {
  width: 2.5rem;
  height: 2.5rem;
  padding: 2rem;
  border-radius: 50%;
  color: #c13332;
  background-color: white;
}

.btn-agent {
  padding: 0.8rem 1.2rem;
  border: none;
  color: #c13332;
  background-color: white;
  border-radius: 1rem;
  font-weight: 700;
  cursor: pointer;
}

.agent-notes {
  text-align: center;
  color: white;
}

#mortgage {
  margin: 5% 10% 1% 10%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m-text {
  width: 45%;
}

.m-text p {
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: 3rem;
}

.m-text a {
  text-decoration: none;
  font-weight: 700;
  padding: 0.8rem 1.2rem;
  color: white;
  border: 1px solid #c13236;
  background-color: #c13332;
  border-radius: 1rem;
  cursor: pointer;
}

.m-image {
  width: 55%;
}

.m-image img {
  width: 100%;
  height: 450px;
  border-radius: 1rem;
}

footer {
  background-color: #c13332;
  margin-top: 3%;
  padding-top: 2%;
  padding-bottom: 1%;
}

#contact {
  margin: 0 10%;
  display: flex;
  justify-content: space-between;
}

.footer-grp-right {
  width: 65%;
  display: flex;
  justify-content: space-between;
}

.footer-grp {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.footer-grp-left {
  margin-top: 1rem;
  color: white;
}

.footer-grp h3 {
  color: white;
}

.footer-logo {
  height: 80px;
  width: 80px;
}

.footer-nav-links {
  display: flex;
  flex-direction: column;
}

.footer-nav-links a {
  text-decoration: none;
  color: white;
}

.footer-nav-links li {
  color: white;
}

.footer-nav-item {
  margin-top: 0.5rem;
  display: flex;
  align-items: flex-start;
}

.footer-icon {
  margin-top: 0.3rem;
  margin-right: 0.8rem;
}

.footer-details p {
  margin-bottom: 0.5rem;
}
.footer-details div {
  display: flex;
  justify-content: space-between;
  width: 13rem;
  margin-bottom: 0.2rem;
}

.copyright {
  margin-top: 2%;
  padding-top: 1rem;
  text-align: center;
  border-top: 1px solid #b2b3b7;
  color: white;
}

.nav-arrow {
  text-decoration: none;
  position: fixed;
  right: 2rem;
  bottom: 2rem;

  color: white;
  border: 1px solid #c13236;
  background-color: #c13236;
  height: 3rem;
  width: 3rem;
  border-radius: 0.5rem;
  text-align: center;
}

.currency_buttons {
  display: flex;
  align-items: center;
  padding: 2% 10% 1% 10%;
}

.modal_form_container {
  position: absolute;
  top: 0;
  height: 500vh;
  width: 100%;
  background-color: #333;
  opacity: 0.97;
}

.contact_container {
  position: absolute;
  left: 25%;
  width: 50%;
  padding: 2%;
  margin-top: 10%;
  background-color: white;
  border-radius: 0.3rem;
}

.contact_container h3 {
  margin-bottom: 2%;
  font-weight: 700;
  color: #c13236;
}

.contact_details {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}

.form-element {
  display: flex;
  flex-direction: column;
  display: flex;
  width: 50%;
  margin-right: 2%;
}

.form-element input,
.form-element textarea,
.form-element button {
  margin-bottom: 5%;
  padding: 2%;
  border-radius: 0.3rem;
  border: 1px solid #c13236;
  font-size: 1rem;
}

.form-element button {
  padding: 2% 4%;
  border: 1px solid #c13236;
  background-color: #c13236;
  font-size: 1rem;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.form_details {
  background-color: #c13236;
  padding: 1% 2%;
  border-radius: 0.3rem;
}

.success_container {
  position: absolute;
  left: 35%;
  width: 30%;
  padding: 2%;
  margin-top: 10%;
  background-color: white;
  border-radius: 0.3rem;
  text-align: center;
}

.success_container h3 {
  color: #c13236;
}
.success_container p {
  margin-top: 5%;
  margin-bottom: 5%;
  font-style: italic;
}

.success_container a {
  padding: 2% 4%;
  border: 1px solid #c13236;
  background-color: #c13236;
  color: white;
  font-weight: 700;
  cursor: pointer;
}

.success_icon {
  color: white;
  background-color: #c13236;
  width: 5rem;
  height: 5rem;
  margin-top: 5%;
  padding: 5%;
  border-radius: 50%;
}

.spinner_container {
  position: absolute;
  left: 35%;
  width: 30%;
  padding: 2%;
  margin-top: 10%;
  background-color: white;
  border-radius: 0.3rem;
  text-align: center;
}

@media screen and (max-width: 1048px) {
  #hero {
    padding-left: 5%;
    padding-right: 5%;
  }

  .d-hero-text {
    width: 45%;
  }
  .d-hero-text h1 {
    font-size: 2rem;
  }

  .d-hero-image {
    width: 50%;
  }

  .d-hero-text a {
    margin-top: 1rem;
  }

  #properties {
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 1%;
  }

  .p-card-notes {
    padding: 0.3rem 0.8rem;
  }

  .p-card-notes svg {
    width: 0.8rem;
  }

  .p-details p {
    font-size: 0.8rem;
  }

  .p-mortgage {
    width: 4rem;
    height: 4rem;
  }

  .p-mortgage p {
    font-size: 10px;
  }

  .p-location,
  .p-type {
    text-align: left;
  }

  #about-us {
    padding-left: 5%;
    padding-right: 5%;
  }

  .services {
    padding-left: 5%;
    padding-right: 5%;
  }

  .about-cta-btn {
    margin-left: 5%;
    width: 17%;
  }

  .agent {
    margin-left: 5%;
    margin-right: 5%;
  }

  .icon-users {
    width: 2rem;
    height: 2rem;
    padding: 1.5rem;
  }

  .btn-agent {
    padding: 0.3rem 0.6rem;
    font-size: 12px;
  }

  .agent-notes {
    width: 80%;
  }

  #mortgage {
    margin-left: 5%;
    margin-right: 5%;
  }

  #contact {
    margin-left: 5%;
    margin-right: 5%;
  }
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 640px) {
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 360px) {
}
