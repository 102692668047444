.navigation-container {
  width: 100%;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5% 10%;
  background-color: #f0f2f1;
}

.navigation a {
  text-decoration: none;
}

.logo {
  height: 80px;
  width: 80px;
}

.nav-links {
  display: flex;
  align-items: center;
}

.nav-links a {
  text-decoration: none;
  color: #666;
  font-weight: 500;
}

.nav-links a:last-child {
  color: white;
}

.nav-item {
  margin-right: 1.5rem;
}

.nav-item:hover {
  color: #333;
  border-bottom: 2px solid #c13236;
}

.cta {
  padding: 0.7rem 1rem;
  cursor: pointer;
  color: white;
  font-weight: 700;
  background-color: #c13236;
  border: none;
  border-radius: 1rem;
}

@media screen and (max-width: 1048px) {
  .navigation {
    padding-left: 8%;
    padding-right: 8%;
  }
}

@media screen and (max-width: 960px) {
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 640px) {
}

@media screen and (max-width: 576px) {
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 360px) {
}
